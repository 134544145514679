import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

// 查询登录主体
export function getCompany(params) {
  return api.get("/user/company/get", params);
}

export function getUsrInfo(params) {
  return api.get("/user/info", params);
}
// 子账号列表
export function getSubAcct(params) {
  return api.get("/company/sub/account/list", params);
}
// 添加子账号
export function addSubAcct(params) {
  return api.post("/company/sub/account/add", params);
}
// 更新子账号
export function updateSubAcct(params) {
  return api.post("/company/sub/account/update", params);
}
// 修改HR信息
export function updateHR(params) {
  return api.post("/company/updateHR", params);
}

// 积分记录列表
export function getPointList(params) {
  return api.get("/user/points/list", params);
}

// 积分使用记录
export function getPointUseList(params) {
  return api.get("/user/points/use/list", params);
}

// 修改密码
export function updatePassword(params) {
  return api.post("/user/password/update", params);
}
// 检查支付密码
export function checkPayPwd(params) {
  return api.get("/user/checkPayPwd", params);
}
// 修改支付密码
export function updatePayPwd(params) {
  return api.post("/user/update/payPassword", params);
}

// 获取用户积分
export function getPoint(params) {
  return api.get("/user/points", params);
}

// 广场审核权限
export function squareAudit(params) {
  let url = "/company/sub/account/squareAudit";
  url = queryUrlFormatter(url, params);
  return api.post(url, params);
}

// 新：创建北森平台密钥
export function createBeisenSecret(params) {
  let url = "/beisen/secret/create";
  return api.post(url, params);
}

// 新：获取北森平台密钥
export function getBeisenSecret(params) {
  let url = "/beisen/secret/get";
  return api.get(url, params);
}

// 新：移除北森平台密钥
export function delBeisenSecret(params) {
  let url = "/beisen/secret/remove";
  return api.post(url, params);
}

// 检查MOKA平台绑定状态和绑定地址
export function getMokaBind(params) {
  return api.get("/moka/bindUrl", params);
}

// 获取moka绑定信息
export function getMokaBindData(params) {
  return api.get("/moka/bindingData", params);
}

// 解除绑定moka
export function delMokaBind(params) {
  return api.get("/moka/removeBinding", params);
}

//飞书：获取密钥
export function getFeiShuSecret(){
  return api.get('/feishu/secret/get');
}

//飞书：创建密钥
export function createFeiShuSecret(){
  return api.post('/feishu/secret/create');
}

//飞书：重置密钥
export function reSetFeiShuSecret(){
  return api.post('/feishu/secret/reset');
}