<template>
  <div>
    <a-tabs v-model:active-key="currentTab" @change="onTabChange">
      <a-tab-pane v-for="i in menuList" :key="i.key" :tab="i.label">
        <!--基本信息-->
        <BaseInfo v-if="i.key === 1" />
        <!--子账号-->
        <!--<Sub v-if="i.key === 2"/>-->
        <!--成长积分  -->
        <!--<Points v-if="i.key === 3"/>-->
        <!--生态绑定-->
        <Ecology v-if="i.key === 4" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import BaseInfo from "./Base.vue";
// import Sub from "./Sub.vue";
// import Points from "./Points.vue";
import Ecology from "./Ecology.vue";
import { getSubAcct } from "./api";
import { FETCH_CODE } from "@/config";
export default {
  components: {
    BaseInfo,
    Ecology
  },
  data() {
    return {
      menuList: [
        {
          label: this.$t("member.base.tab"),
          key: 1
        },
        // {
        //   label: this.$t("member.acct.tab"),
        //   key: 2
        // },
        // {
        //   label: this.$t("member.score.tab"),
        //   key: 3
        // },
        {
          label: this.$t("member.bind.tab"),
          key: 4
        }
      ],
      currentTab: 1
    };
  },
  mounted() {
    // this.getCompanyInfo()
  },
  methods: {
    onTabChange(tab) {
      this.currentTab = tab;
    },

    getCompanyInfo() {
      getSubAcct({
        pageNum: 1,
        pageSize: 10
      })
        .then(res => {
          console.log(res);
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
        })
        .catch(e => {});
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-tabs-bar {
  margin-bottom: 40px;
}
</style>
